<template>
  <div
    v-if="!haveUnsupportedField"
    class="ExportTasksPreview text-truncate"
  >
    <h3
      class="ExportTasksPreview__heading"
      v-text="'Preview before sending to jira'"
    />

    <div class="ExportTasksPreview__summary text-truncate">
      <div class="ExportTasksPreview__meta">
        <span class="ExportTasksPreview__meta-label mr-3">{{ $t('integration.IssueType') }}</span>
        <span class="d-inline-flex align-center mr-6">
          <img
            v-if="taskMeta.taskTypeIconUrl"
            :alt="taskMeta.taskTypeName"
            :src="taskMeta.taskTypeIconUrl"
            class="mr-2"
          >
          <v-icon
            v-else
            :size="16"
            class="mr-2"
            style="margin-top: -2px"
            v-text="'mdi-help-circle-outline'"
          />
          <span v-text="taskMeta.taskTypeName" />
        </span>

        <span class="ExportTasksPreview__meta-label mr-3">{{ $t('integration.Project') }}</span>
        <span
          class="d-inline-flex align-center mr-6"
          v-text="(jiraProject && `[${jiraProject.key}] ${jiraProject.name}`) || $t('integration.UnknownProject')"
        />

        <!--        <span class="ExportTasksPreview__meta-label mr-3">Assignee</span>-->
        <!--        <span-->
        <!--          v-if="taskMeta.assigneeName"-->
        <!--          class="d-inline-flex align-center"-->
        <!--        >-->
        <!--          <img-->
        <!--            v-if="taskMeta.assigneeAvatarUrl"-->
        <!--            alt="avatar"-->
        <!--            :src="taskMeta.assigneeAvatarUrl"-->
        <!--            class="mr-2"-->
        <!--          >-->
        <!--          <span v-text="taskMeta.assigneeName" />-->
        <!--        </span>-->
      </div>
      <h1
        class="display-1 text-truncate mb-2 mt-5"
        v-text="currentIssue && currentIssue.name"
      />
    </div>

    <div class="ExportTasksPreview__scrollable">
      <div
        v-if="errorInIssues && currentIssueError"
        class="mb-5"
      >
        <v-icon
          color="#FFC480"
          v-text="'mdi-alert-outline'"
        />
        <span
          class="ml-2"
          v-text="currentIssueError.error"
        />
      </div>

      <v-form
        v-if="requiredFields && requiredFields.length"
        class="SetupJiraForm__form-status"
      >
        <h3
          v-if="requiredFields && requiredFields.length"
          class="mb-4"
        >
          {{ $t('integration.JiraRequiredFields') }}
        </h3>
        <JiraRequiredFields
          v-for="(field, index) in requiredFields"
          :key="index"
          :field="field"
          :required="true"
          :integration-id="integration.id"
          :saved-field-value="savedRequiredField(field)"
          @change="changeRequiredValues($event)"
        />

        <v-btn
          v-if="requiredFields && requiredFields.length"
          outlined
          color="primary"
          style="border: none"
          class="mb-5"
          :disabled="isAllValueSame"
          @click="$emit('apply-set-value', { issueId: currentIssue.id })"
        >
          <v-icon
            :color="isAllValueSame ? '#c9c9c9' : '#2196F3'"
            v-text="'$copy-value'"
          />
          <span class="ml-2">{{ $t('integration.ApplyForAllIssuesM') }}</span>
        </v-btn>
      </v-form>

      <div
        v-for="paragraph in paragraphs"
        :key="paragraph.key"
        class="pt-4"
      >
        <b>{{ paragraph.name }}</b><br>
        <p
          :style="paragraph.style"
          v-text="paragraph.text"
        />
      </div>

      <v-form
        v-if="nonRequiredFields && nonRequiredFields.length"
        class="SetupJiraForm__form-status"
      >
        <div v-if="showOptionalFields">
          <h3 class="mb-4 pt-4">
            {{ $t('integration.JiraOptionalFields') }}
          </h3>
          <JiraRequiredFields
            v-for="(field, index) in nonRequiredFields"
            :key="index"
            :field="field"
            :required="false"
            :integration-id="integration.id"
            :saved-field-value="savedNonRequiredField(field)"
            :show-unsupported-fields="true"
            @change="changeNonRequiredValues($event)"
          />

          <v-btn
            outlined
            color="primary"
            style="border: none"
            class="mb-5"
            :disabled="isAllNonRequiredValueSame"
            @click="$emit('apply-set-value-non-required', { issueId: currentIssue.id })"
          >
            <v-icon
              :color="isAllNonRequiredValueSame ? '#c9c9c9' : '#2196F3'"
              v-text="'$copy-value'"
            />
            <span class="ml-2">{{ $t('integration.ApplyForAllIssuesM') }}</span>
          </v-btn>
        </div>
        <v-btn
          v-if="nonRequiredFields && nonRequiredFields.length"
          text
          color="primary"
          style="display:block"
          @click="showOptionalFields = !showOptionalFields"
        >
          {{ showOptionalFields ? $t('integration.HideOptional') : $t('integration.ShowOptional') }}
        </v-btn>
      </v-form>
    </div>

    <div class="ExportTasksPreview__footer">
      <div
        v-if="!isThereEmptyFields"
        class="mr-auto"
      >
        <span>
          {{ currentIssueIx + 1 }} / {{ issues.length }}
        </span>
      </div>
      <div v-else>
        <v-icon
          color="#FFC480"
          v-text="'mdi-alert-outline'"
        />
        <span class="ExportTasksPreview__footer-warning ml-2">{{ $t('integration.FillRequiredFields') }}</span>
      </div>

      <v-spacer />

      <v-btn
        :disabled="!issues || currentIssueIx <= 0"
        outlined
        color="primary"
        class="mr-2"
        width="20"
        min-width="20"
        @click="currentIssueIx--"
      >
        <v-icon v-text="'mdi-chevron-left'" />
      </v-btn>
      <v-btn
        :disabled="!issues || currentIssueIx < 0 || currentIssueIx >= (issues.length - 1)"
        outlined
        color="primary"
        class="mr-2"
        width="20"
        min-width="20"
        @click="currentIssueIx++"
      >
        <v-icon v-text="'mdi-chevron-right'" />
      </v-btn>

      <v-btn
        :disabled="pending || isThereEmptyFields || !meta"
        :loading="pending"
        depressed
        color="primary"
        @click="$emit('submit')"
      >
        {{ $tc('integration.SendIssueN', issues.length) }}
      </v-btn>
      <v-btn
        :disabled="pending"
        class="ml-2"
        outlined
        color="primary"
        @click="$emit('close')"
      >
        {{ $t('integration.Cancel') }}
      </v-btn>
    </div>
  </div>
  <JiraUnsupportedFields
    v-else
    :project-id="projectId"
    :unsupported-field="unsupportedField"
  />
</template>

<script>
import lodash from 'lodash/fp'

import { JIRA } from '../constants'

import Project from '../store/orm/project'

import JiraRequiredFields from './JiraRequiredFields'
import JiraUnsupportedFields from './JiraUnsupportedFields'

export default {
  name: 'ExportTasksPreview',

  components: {
    JiraRequiredFields,
    JiraUnsupportedFields,
  },

  props: {
    visible: { type: Boolean, default: true }, // parent dialog model value
    pending: { type: Boolean, default: true }, // sending issues
    projectId: { type: String, default: null },
    issues: { type: Array, default: () => [] },
    issuesRequiredFields: { type: Object, default: () => {} },
    issuesNonRequiredFields: { type: Object, default: () => {} },
    integrationId: { type: String, default: null },
    errorInIssues: { type: Array, default: null },
  },

  data() {
    return {
      currentIssueIx: -1,
      showOptionalFields: false,
    }
  },

  computed: {
    isAllValueSame() {
      const { issuesRequiredFields } = this

      const fields = Object.values(issuesRequiredFields)

      return fields.every((field) => {
        return lodash.isEqual(fields[0].fieldsValues, field.fieldsValues)
      })
    },

    isAllNonRequiredValueSame() {
      const { issuesNonRequiredFields } = this

      const fields = Object.values(issuesNonRequiredFields)

      return fields.every((field) => {
        return lodash.isEqual(fields[0].fieldsValuesNonRequired, field.fieldsValuesNonRequired)
      })
    },

    project() {
      return Project.find(this.projectId)
    },

    integration() {
      return this.$store.getters['integration/get'](this.integrationId)
    },

    currentIssue() {
      const { currentIssueIx, issues } = this
      if (currentIssueIx === -1 || !issues?.length) return null
      return issues?.[currentIssueIx] || null
    },

    fetchProjectWatch() {
      const { isVisible, projectId } = this
      return { shouldFetch: isVisible, projectId }
    },

    jiraProject() {
      const { integration } = this
      if (!integration) return null
      if (integration.integrationCode !== JIRA) return null
      if (!integration.jiraProjectKey || !integration.meta) return null
      return integration.meta.projects
        .find(({ key }) => key === integration.jiraProjectKey) || null
    },

    jiraIssueType() {
      const { integration, jiraProject: project } = this
      if (!project || !integration.jiraIssueType) return null

      return project.issuetypes
        .find(({ name, id }) => {
          if (integration.jiraIssueType?.id && id === integration.jiraIssueType.id) return true
          // fixme: explain/fix this, no idea what's happening here, but looks bad
          if (!integration.jiraIssueType?.id && name === (integration.jiraIssueType?.name || integration.jiraIssueType)) return true
        }) || null
    },

    taskMeta() {
      const { jiraIssueType } = this

      if (jiraIssueType) {
        return {
          taskTypeIconUrl: jiraIssueType.iconUrl,
          taskTypeName: jiraIssueType.name,
          assigneeAvatarUrl: require('../assets/images/jira-unassigned.png'),
          assigneeName: 'Automatic',
        }
      }

      return {
        taskTypeIconUrl: null,
        taskTypeName: 'Task',
        assigneeAvatarUrl: null,
        assigneeName: null,
      }
    },

    paragraphs() {
      const { currentIssue: issue } = this

      return issue && [
        {
          name: this.$t('integration.ParagraphGeneralDescription'),
          key: 'generalDescription',
          text: issue?.data?.generalDescription,
        },
        {
          name: this.$t('integration.ParagraphRecommendations'),
          key: 'recommendations',
          text: issue?.data?.recommendations,
        },
        {
          name: this.$t('integration.ParagraphTechnicalDescription'),
          key: 'techincalDescription',
          text: issue?.data?.technicalDescription,
        },
        {
          name: this.$t('integration.ParagraphStepsToReproduce'),
          key: 'reproduceDescription',
          text: issue?.data?.reproduceDescription,
        },
        {
          name: this.$t('integration.ParagraphRiskDescription'),
          key: 'risksDescription',
          text: issue?.data?.risksDescription,
        },
        issue.ips.length && {
          name: this.$t('integration.ParagraphAffectedIps'),
          key: 'ips',
          text: issue.ips.join('\n'),
          style: { whiteSpace: 'pre-wrap' },
        },
        issue.hostnames.length && {
          name: this.$t('integration.ParagraphAffectedHostnames'),
          key: 'hostnames',
          text: issue.hostnames.join('\n'),
          style: { whiteSpace: 'pre-wrap' },
        },
      ]
        .filter(Boolean)
    },

    meta() { return this.integration?.meta || null },

    haveUnsupportedField() {
      return this.meta?.requiredFields?.some(field => field.type === 'UNSUPPORTED') || false
    },

    unsupportedField() {
      const unsupportedRequiredFields = this.meta?.requiredFields?.filter(field => field.type === 'UNSUPPORTED') || []
      const unsupportedNonRequiredFields = this.meta?.nonRequiredFields?.filter(field => field.type === 'UNSUPPORTED') || []

      return [...unsupportedRequiredFields, ...unsupportedNonRequiredFields]
    },

    requiredFields() {
      const { meta } = this
      return meta?.requiredFields || []
    },

    nonRequiredFields() {
      const { meta } = this
      return meta?.nonRequiredFields || null
    },

    isThereEmptyFields() {
      const { issuesRequiredFields } = this

      return Object
        .values(issuesRequiredFields)
        .some(({ fieldsValues }) =>
          fieldsValues.some(({ value }) =>
            value == null ||
            value === '' ||
            (Array.isArray(value) && !value.length),
          ),
        )
    },

    currentIssueError() {
      const { currentIssue, errorInIssues } = this

      if (!errorInIssues) return null
      return errorInIssues.find(issue => issue.id === currentIssue.id) || null
    },
  },

  watch: {
    issues: {
      immediate: true,
      handler(issues) {
        if (!issues?.length) this.currentIssueIx = -1
        else if (this.currentIssueIx === -1) this.currentIssueIx = 0
      },
    },

    fetchProjectWatch: {
      deep: true,
      immediate: true,
      handler({ shouldFetch }) {
        if (shouldFetch) this.fetchProject()
      },
    },

    integrationId: {
      immediate: true,
      async handler(integrationId) {
        if (integrationId) await this.fetchIntegrationMeta()
      },
    },

    $route() {
      this.$store.commit('$snackbar/setMessage', { message: null })
    },

    visible(isVisible) {
      if (!isVisible) this.showOptionalFields = false
    },
  },

  methods: {
    savedRequiredField(field) {
      const { currentIssue, issuesRequiredFields } = this
      if (!currentIssue) return

      const defVal = issuesRequiredFields[currentIssue.id]?.fieldsValues?.find(value => value.field === field.name)

      if (defVal) return defVal.value
      return null
    },

    savedNonRequiredField(field) {
      const { currentIssue, issuesNonRequiredFields } = this

      if (!currentIssue) return

      const defVal = issuesNonRequiredFields[currentIssue.id]?.fieldsValuesNonRequired?.find(value => value.field === field.name)

      if (!lodash.isEmpty(defVal?.value)) return defVal.value
      return null
    },

    changeRequiredValues(e) {
      const { currentIssue } = this
      const { field: { name }, event } = e

      this.$emit('change-required-issue-field', { issueId: currentIssue.id, fieldName: name, value: event })
    },

    changeNonRequiredValues(e) {
      const { currentIssue } = this
      const { field: { name }, event } = e

      this.$emit('change-non-required-issue-field', { issueId: currentIssue.id, fieldName: name, value: event })
    },

    fetchProject() {
      const { projectId } = this
      return Project.dispatch('$getOne', { projectId, reload: false })
    },

    async ensureIntegration() {
      await this.$store.dispatch('integration/getForProject', {
        projectId: this.projectId,
        reload: false,
      })
      if (!this.integration) throw new Error('Programming error')
      return this.integration
    },

    async fetchIntegrationMeta() {
      const { integrationCode } = await this.ensureIntegration()
      if (integrationCode === JIRA) return this.fetchJiraMeta()
      throw new Error('Unhandled integration type: ' + integrationCode)
    },

    fetchJiraMeta() {
      const { $store, integrationId } = this
      return $store.dispatch('integration/getJiraMeta', { integrationId, reload: false })
    },
  },
}
</script>

<style lang="sass" scoped>
.ExportTasksPreview
  width: 100%

  &.unsupported
    width: 100%

    & span
      font-size: 14px
      color: #A09EB9

  &__heading
    display: flex
    align-items: center
    height: 60px
    padding: 16px 28px
    background: #F5F5F9
    color: #A09EB9
    font-weight: 500

  &__jira-logo
    margin-right: 14.33px
    height: 32px
    width: 89px

  &__title
    position: relative
    top: 3px

  &__summary
    height: 80px
    margin: 16px 28px

  &__meta
    font-size: 14px
    line-height: 24px
    display: flex

  &__meta-label
    color: #A09EB9

  &__scrollable
    height: 342px
    overflow: hidden auto
    margin-left: 28px
    margin-right: 28px
    font-size: 13px
    line-height: 20px
    white-space: normal

    &.unsupported
      height: 452px
      padding: 0 20px 20px 0
      margin-right: 0

  &__footer
    border-top: 1px solid rgba(0, 0, 0, .12)
    display: flex
    align-items: center
    height: 74px
    padding: 0 28px
    background: #F5F5F9

    &-warning
      color: #666699
      font-size: 12px
</style>
