<template>
  <component
    :is="componentName"
    v-if="fieldType === 'ARRAY' && field.items === 'SEARCH_USER'"
    :id="field.name"
    :value="value"
    :label="`${displayName} (${$t('integration.typeToSearchM')})`"
    :items="searchItems"
    :loading="isLoading"
    :search-input.sync="searchQuery"
    :required="required"
    :rules="required ? [requiredRules] : []"
    hide-details
    filled
    :no-data-text="$t('integration.NothingFound')"
    clearable
    item-text="displayName"
    return-object
    multiple
    chips
    deletable-chips
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
  />
  <component
    :is="componentName"
    v-else-if="fieldType === 'SEARCH_USER' || fieldType === 'SEARCH_EPIC' || fieldType === 'SEARCH_SPRINT'"
    :id="field.name"
    v-model="value"
    :label="`${displayName} (${$t('integration.typeToSearchM')})`"
    :items="searchItems"
    :loading="isLoading"
    :required="required"
    :rules="required ? rules : []"
    hide-details
    filled
    :no-data-text="$t('integration.NothingFound')"
    item-text="displayName"
    item-id="id"
    return-object
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
    @focusin="fieldType === 'SEARCH_SPRINT' ? findInJira(' ') : null"
    @update:search-input="search"
  />
  <component
    :is="componentName"
    v-else-if="fieldType === 'ARRAY' && field.items === 'STRING'"
    :id="field.name"
    :value="value"
    :label="`${displayName} (${$t('integration.addNewM')})`"
    :items="searchItems"
    :loading="isLoading"
    :required="required"
    :rules="required ? [requiredRules] : []"
    hide-details
    filled
    hide-no-data
    clearable
    item-text="displayName"
    return-object
    multiple
    chips
    deletable-chips
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
  />
  <component
    :is="componentName"
    v-else-if="fieldType === 'ARRAY' && field.items === 'ENUM'"
    :id="field.name"
    :value="value"
    :label="displayName"
    :required="required"
    :items="allowedValues"
    :rules="required ? rules : []"
    return-object
    multiple
    :item-text="item => item.displayName || item.name || item.value"
    hide-details
    filled
    dense
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
  />
  <component
    :is="componentName"
    v-else-if="fieldType === 'ENUM'"
    :id="field.name"
    :value="value"
    :label="displayName"
    :item-text="item => item.displayName || item.name || item.value"
    :required="required"
    :items="allowedValues"
    :rules="required ? rules : []"
    return-object
    hide-details
    filled
    dense
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
  />
  <component
    :is="componentName"
    v-else-if="fieldType === 'STRING'"
    :id="field.name"
    :value="value"
    :label="displayName"
    :required="required"
    :rules="required ? rules : []"
    filled
    hide-details
    dense
    class="mb-5"
    @change="$emit('change', { event: $event, field })"
    @keydown.enter.prevent
  />
  <v-menu
    v-else-if="fieldType === 'DATE'"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :id="field.name"
        :value="value"
        :label="displayName"
        readonly
        filled
        dense
        clearable
        v-bind="attrs"
        v-on="on"
        @change="$emit('change', { event: $event, field })"
      />
    </template>
    <v-date-picker
      @change="$emit('change', { event: $event, field })"
      @input="changeDate"
    />
  </v-menu>
  <v-text-field
    v-else-if="fieldType === 'UNSUPPORTED' && showUnsupportedFields"
    :id="field.name"
    :value="value"
    :label="displayName"
    :required="required"
    :rules="required ? rules : []"
    filled
    :readonly="isUnsupportedFieldsDisabled"
    :disabled="isUnsupportedFieldsDisabled"
    dense
    class="mb-2"
    :messages="field.name"
    @change="$emit('change', { event: $event, field })"
    @keydown.enter.prevent
  >
    <template #prepend-inner>
      <v-tooltip
        top
        open-delay="500"
      >
        <template #activator="{ on, attrs }">
          <v-icon
            color="#FFC480"
            v-bind="attrs"
            v-on="on"
            @click="copyToClipboard"
            v-text="'mdi-alert-outline'"
          />
        </template>

        <span
          style="white-space: pre-wrap"
          v-text="getDetailsInString(field.systemDetails)"
        />
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import {
  JIRA_ISSUE_REQUIRED_FIELDS_TYPE as requiredFieldType,
  JIRA_ISSUE_REQUIRED_FIELDS_TYPE_COMPONENT as requiredFieldComponent,
} from '@/constants'

import { debounce } from 'throttle-debounce'
import _ from 'lodash'
import { handleError } from '@/helpers'
import copyToClipboard from 'copy-to-clipboard'

export default {
  name: 'JiraRequiredFields',

  props: {
    field: { type: Object, required: true },
    required: { type: Boolean, default: false },
    integrationId: { type: String, default: null },
    savedFieldValue: { type: [Object, Array, String], required: false },
    showUnsupportedFields: { type: Boolean, default: false },
    isUnsupportedFieldsDisabled: { type: Boolean, default: true },
  },

  data() {
    return {
      requiredFieldType,
      requiredFieldComponent,

      searchItems: [],
      isLoading: false,
      searchQuery: null,

      rules: [v => !!v],

      value: null,
      menu: false,
    }
  },

  computed: {
    displayName() {
      const { field } = this
      return field?.displayName || this.$t('integration.Unknown')
    },

    componentName() {
      const { requiredFieldComponent, fieldType, field } = this

      if (fieldType === 'ARRAY') {
        return requiredFieldComponent[fieldType][field.items]?.componentName || null
      }

      return requiredFieldComponent[fieldType]?.componentName || null
    },

    fieldType() {
      const { field } = this
      return field?.type || null
    },

    defaultValue() {
      const { field, savedFieldValue } = this

      return savedFieldValue || field?.defaultValue || ''
    },

    allowedValues() {
      const { field } = this
      return field?.allowedValues || null
    },
  },

  watch: {
    searchQuery(v) {
      // Items have already been requested
      if (this.isLoading) return
      this.findInJira(v)
    },

    defaultValue: {
      immediate: true,
      handler(value) {
        const { fieldType } = this

        if (fieldType === 'SEARCH_USER' || fieldType === 'SEARCH_EPIC' || fieldType === 'SEARCH_SPRINT') {
          this.searchItems = value ? [value] : []
          this.value = value
          return
        }

        if (Array.isArray(value)) {
          this.searchItems = value
          this.value = value
        } else {
          this.value = value
        }
      },
    },
  },

  methods: {
    changeDate(v) {
      this.value = v
      this.menu = false
    },

    getDetailsInString(field) {
      const text = `${this.$t('integration.ClickToCopy')} \n`
      return text + JSON.stringify(field, null, '\t')
    },

    copyToClipboard() {
      const value = JSON.stringify(this.field.systemDetails, null, '\t')

      copyToClipboard(value)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('integration.Copied'),
        timeout: 2000,
      })
    },

    search(e) {
      if (e?.length && this.value?.displayName !== e) this.findInJira(e)
    },

    requiredRules(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('integration.Required')
      }
      return !!value || this.$t('integration.Required')
    },

    findInJira: debounce(250, false, async function(searchQuery) {
      const { integrationId, field: { name, type } } = this

      // for SEARCH_SPRINT we need to send empty string to get some values
      // for other field search only when user start typing
      if (searchQuery?.length >= 1 || type === 'SEARCH_SPRINT') {
        this.isLoading = true
        await this.$store.dispatch('integration/integrationJiraSearch', { integrationId, field: name, query: searchQuery })
          .then(r => {
            const union = _.cloneDeep([...this.searchItems, ...r])
            this.searchItems = _.uniqBy(union, 'id')
          })
          .catch(e => handleError(this.$store, e).catch(() => {}))
          .finally(() => (this.isLoading = false))
      }
    }),
  },
}
</script>

<style scoped>

</style>
