// enables portal-vue redirection of the ajax progress bar
// when certain watch criteria is satisfied
//
// Example, <script> section:
//   export default {
//     name: 'MyComponent',
//     mixins: [ajaxPortWhen('isLoading')],
//     data: () => ({ isLoading: true }),
//   }
// And somewhere in the <template>:
//   <PortalTarget name="ajax-bar" />

export default (
  watchExpression,
  options = { immediate: true, deep: false },
) => {
  let unwatch
  return {
    created() {
      unwatch = this.$watch(watchExpression, (portal) => {
        this.$store.commit('ajaxBar/setPortal', !!portal)
      }, options)
    },

    beforeDestroy() {
      unwatch?.() // eslint-disable-line
      this.$store.commit('ajaxBar/setPortal', false)
    },
  }
}
