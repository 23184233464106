<template>
  <div class="JiraUnsupportedFields">
    <h3
      class="JiraUnsupportedFields__heading"
      v-text="$t('integration.PreviewBeforeJiraUnsupportedFieldsM')"
    />
    <div class="JiraUnsupportedFields__scrollable">
      <h2 class="mb-4 mt-8">
        {{ $t('integration.UnsupportedFieldsM') }}
      </h2>
      <span>{{ $t('integration.UnsupportedFilesInIssueM') }}</span><br>
      <span>{{ $t('integration.SendDetailsTo') }}
        <a :href="`mailto:${$t('layout.emailSupport')}?subject=${$t('integration.JiraUnsupportedFields')}&body=${unsupportedFieldName}`">{{ $t('layout.emailSupport') }}</a>
      </span>

      <PreCopy
        v-for="field in unsupportedField"
        :key="field.name"
        :title="field.displayName || $t('integration.UnsupportedField')"
        :text="getDetailsInString(field.systemDetails)"
      />
    </div>
    <div class="JiraUnsupportedFields__footer">
      <v-spacer />

      <v-btn
        depressed
        color="primary"
        @click="copyToClipboard"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-content-copy
        </v-icon>
        {{ $t('integration.Copy') }}
      </v-btn>
      <v-btn
        v-if="isAdmin"
        class="ml-2"
        outlined
        color="primary"
        :to="integrationRoute"
      >
        {{ $t('integration.CreateOther') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PreCopy from '@/components/PreCopy'
import copyToClipboard from 'copy-to-clipboard'

export default {
  name: 'JiraUnsupportedFields',

  components: {
    PreCopy,
  },

  props: {
    unsupportedField: { type: Array, required: true },
    projectId: { type: String, default: null },
  },

  computed: {
    unsupportedFieldName() {
      return this.unsupportedField.map(field => field.displayName).join(', ')
    },

    integrationRoute() {
      const { projectId } = this
      return {
        name: 'Integration',
        params: { projectId },
        query: { dialog: 'integration', type: 'JIRA' },
      }
    },

    isAdmin() { return this.$store.getters['user/current']?.isAdmin },
  },

  methods: {
    copyToClipboard() {
      const value = JSON.stringify(this.unsupportedField, null, '\t')

      copyToClipboard(value)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('integration.Copied'),
        timeout: 2000,
      })
    },

    getDetailsInString(field) {
      return JSON.stringify(field, null, '\t')
    },
  },
}
</script>

<style lang="sass" scoped>
.JiraUnsupportedFields
  width: 100%

  & span
    font-size: 14px
    color: #A09EB9

  &__heading
    display: flex
    align-items: center
    height: 60px
    padding: 16px 28px
    background: #F5F5F9
    color: #A09EB9
    font-weight: 500

  &__scrollable
    height: 452px
    overflow: hidden auto
    margin-left: 28px
    margin-right: 0
    font-size: 13px
    line-height: 20px
    white-space: normal
    padding: 0 20px 20px 0

  &__footer
    border-top: 1px solid rgba(0, 0, 0, .12)
    display: flex
    align-items: center
    height: 74px
    padding: 0 28px
    background: #F5F5F9

    &-warning
      color: #666699
      font-size: 12px
</style>
