<template>
  <div class="PreCopy">
    <div class="PreCopy__heading">
      <span
        class="PreCopy__title"
        v-text="title + ':'"
      />
      <v-btn
        class="PreCopy__copy-btn"
        color="primary"
        icon
        small
        :title="$t('layout.CopyToClipboard')"
        @click="copyToClipboard"
      >
        <v-icon
          size="18"
          v-text="'mdi-content-copy'"
        />
      </v-btn>
    </div>

    <pre
      class="PreCopy__pre"
      v-text="text"
    />
  </div>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard'

export default {
  name: 'PreCopy',

  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
  },

  methods: {
    copyToClipboard() {
      copyToClipboard(this.text)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('layout.Copied'),
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.PreCopy
  & + &
    margin-top: 24px

  &__heading
    display: flex
    align-items: center
    margin-bottom: 16px
    font-size: 14px
    line-height: 24px

  &__pre
    white-space: pre-wrap
    padding: 28px
    border: 1px solid #DCE0EE
    border-radius: 4px
    background: #fafafc

    font-family: Consolas monospace
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px

    overflow: auto
    max-height: 600px
</style>
